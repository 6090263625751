/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import {useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  let dark = true
  const [animatedOn, setAnimated] = useState(true);

  return (
    <>
      {/*<Header siteTitle={data.site.siteMetadata?.title || `Title`} />*/}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 0 20px`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `1rem`,
            textAlign:"center",
          }}
        >
          <div>While you wait for tomorrow, check out: </div>
          <a className="thicc" href="https://emotle.com" style={{textDecoration:"none", fontSize:"1.1rem", color:"#ff30dc"}}>
            Emotle <br/>
            <span style={{color:"#FFF",display:'inline',textDecoration:'underline'}}>The Emoji Translation Game </span>
            <br/>
          </a>
          <br/>
          
          <span style={{color:"#FFF",display:'inline',fontFamily:'Lato', fontSize:"1.1rem"}}>© {new Date().getFullYear()}, Definle </span> <br/>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
